.product_name {
  text-align: center;
  margin-top: 4rem;
}

.product_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  align-items: start;
}

.product_item {
  background-color: #f8f7e5;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid rgb(240, 233, 227);
}

.product_item:hover {
  transform: scale(1.01);
  /* background-image: linear-gradient(360deg, #fbfebc, #dfe0cd); */
  background-image: linear-gradient(360deg, #f7f8e8, #dfe0cd);
}

.product_item h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.product_item p {
  font-size: 1rem;
  color: #777;
}

.product_item_main {
  display: grid;
  place-items: center;
  background-color: #f8f7e5;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 3rem;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 3rem;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid rgb(240, 233, 227);
}

.product_item_main:hover {
  transform: scale(1.01);
  background-image: linear-gradient(360deg, #f7f8e8, #dfe0cd);
}

.product_item_main h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.product_item_main p {
  font-size: 1rem;
  color: #777;
}

@media only screen and (max-width: 600px) {
  .product_grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .product_grid {
    grid-template-columns: 1fr;
  }
}

/* .text_align_left {
  text-align: left;
} */
