.contactus_main {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.maps_container {
  display: flex;
  gap: 3rem;
  margin-top: 2rem;
  justify-content: center;
}

.street_view {
  border: 2px solid black;
}

.google_map {
  border: 2px solid black;
}

.maps_container iframe {
  width: 400px;
  height: 300px;
}

.logo_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#logo {
  width: 300px;
  height: 50px;
}

.container_main {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#logo_hand {
  height: 320px;
}
@media only screen and (max-width: 600px) {
  .maps_container {
    flex-direction: column;
    align-items: center;
  }
  .maps_container iframe {
    max-width: 350px;
    max-height: 250px;
  }

  .street_view,
  .google_map {
    border: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 880px) {
  .maps_container {
    flex-direction: column;
  }
  .street_view,
  .google_map {
    border: none;
  }

  .maps_container iframe {
    max-width: 100%;
    max-height: 100%;
  }
}
