/*
.gallery_name {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery img {
  margin: 10px;
  width: 200px;
  height: 220px;
  cursor: pointer;
} */
.gallery_name {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10rem;
}

.gallery img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.gallery img:hover {
  transform: scale(1.15);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
}

.modal img {
  max-width: 100%;
  max-height: 80vh;
}

.close,
.prev,
.next {
  position: absolute;
  color: white;
  font-size: 40px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.close:hover,
.prev:hover,
.next:hover {
  color: rgb(117, 152, 241);
}

.prev,
.next {
  top: 50%;
  transform: translateY(-50%);
}

.prev {
  left: -70px;
}

.next {
  right: -70px;
}
.close {
  right: -70px;
}

@media only screen and (max-width: 660px) {
  .gallery img {
    width: 80%;
    height: auto;
  }

  .modal {
    display: none;
  }
  .gallery img:hover {
    transform: scale(1);
  }
}

@media only screen and (min-width: 665px) and (max-width: 800px) {
  .modal img {
    max-width: 75%;
    max-height: 80vh;
    display: block;
    margin: 0 auto; /* Középre igazítás */
  }
  .modal-content {
    position: relative;
  }
  .prev {
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }

  .next {
    right: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
  .close {
    right: 0%;
    top: 0%;
  }
}
