html {
  background-color: #f8f7e5;
}

.main-container {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-right: 210px;
}

.image1 {
  margin-top: 4rem;
  margin-bottom: 20px;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image1 img {
  max-width: 40%;
  height: auto;
}

.text1 {
  text-align: center;
  margin-left: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f2f2f2;
  background-image: linear-gradient(to bottom right, #ffffff, #f2f2f2);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.text1 p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.text1 p:last-child {
  margin-bottom: 0;
}

/* mozgó sáv*/

.marquee-container {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 40s linear infinite;
  height: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.marquee span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  font-size: 40px;
  color: #333;
}

.marquee span i {
  margin-right: 10px;
  font-size: 20px;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Section 2 */

.container2 {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-right: 50px;
  margin-top: 1rem;
  flex-wrap: nowrap;
  align-items: center;
}
/*
.container2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80%;
  margin-right: 50px;
  margin-top: 1rem;
} */

.image2 {
  margin-top: 4rem;
  margin-bottom: 20px;
  margin-left: 10%;
}

.image2 img {
  width: 400px;
  height: auto;
}

.text2 {
  text-align: center;
  margin-left: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  /*background-color: #f2f2f2;*/
  background-image: linear-gradient(to bottom right, #ffffff, #fbfebc);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.text2 p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.text2 p:last-child {
  margin-bottom: 0;
}

/* Section 3 */

.container3 {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-right: 50px;
  margin-top: 1rem;
  flex-wrap: nowrap;
  align-items: center;
}

.image3 {
  margin-top: 4rem;
  margin-bottom: 20px;
  margin-left: 10%;
}

.image3 img {
  height: auto;
  width: 400px;
}

.text3 {
  text-align: center;
  margin-left: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f2f2f2;
  background-image: linear-gradient(to bottom right, #ffffff, #fbfebc);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.text3 p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.text3 p:last-child {
  margin-bottom: 0;
}

/* Section 4 */

.container4 {
  margin: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  text-align: center;
}

.section {
  background-image: linear-gradient(360deg, #fbfebc, #f7e7e6);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.section:hover {
  transform: translateY(-50px);
}

.section h4 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.section p {
  font-size: 1rem;
  line-height: 1.5;
}
/*
@media (max-width: 768px) {
  .container4 {
    grid-template-columns: 1fr;
  }
}
*/

/* Modal */

.half-modal-container {
  width: 800px;
  height: 50vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(360deg, #fbfebc, #f7e7e6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  padding: 20px;
  overflow-y: auto; /* Engedélyezd az elcsúsztatható tartalmat, ha túllépi a méretet */
  text-align: center;
}

.half-modal-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.half-modal-container p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.half-modal-container button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.half-modal-container button:hover {
  background-color: #555;
}

/* Pedicure modal */
.pedicure {
  text-align: center;
  margin-left: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  /*background-color: #f2f2f2;*/
  background-image: linear-gradient(to bottom right, #ffffff, #e8eac3);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.pedicure p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.pedicure p:last-child {
  margin-bottom: 0;
}

.pedicure h3 {
  margin-top: 0;
}

.pedicure span {
  display: block;
}

.pedicure li {
  margin-left: 20px;
}

/* Pedicure Main */
.pedicureMain {
  text-align: center;
  margin-left: 20px;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f2f2f2;
  background-image: linear-gradient(to bottom right, #ffffff, #b3afaf);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.pedicureMain p {
  margin-bottom: 20px;
  line-height: 1.5;
}

.pedicureMain p:last-child {
  margin-bottom: 0;
}

.pedicureMain h3 {
  margin-top: 0;
}

.pedicureMain span {
  display: block;
}

.pedicureMain li {
  margin-left: 20px;
}

h1 {
  text-align: center;
}

#logo_home {
  height: 60px;
  width: 200px;
}

.logoContainer_home {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
}

#facebook {
  color: blue;
}

.container {
  position: relative;
  width: 100%;
}

.image0 {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  height: 700px;
}

.image0 img {
  display: block;
  margin: 0 auto;
  object-fit: contain;
  max-width: 100%;
}

.text1 {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  text-align: center;
  color: #000;
  opacity: 0.9;
  border-radius: 20px;
}

.text1 h1 {
  font-family: Arial, sans-serif;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
  position: relative;
  margin-top: 3px;
}

.text1 h1::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(
    to right,
    #e74c3c,
    #f1c40f,
    #2ecc71,
    #3498db,
    #9b59b6,
    #e74c3c
  );
  z-index: -1;
}

.rainbow-text {
  background-image: linear-gradient(
    to right,
    #e74c3c,
    #f1c40f,
    #2ecc71,
    #3498db,
    #9b59b6,
    #e74c3c
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text1 p {
  font-family: Arial, sans-serif;
  font-size: 16px;
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .container,
  .container2,
  .container3,
  .container4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    max-width: 600px;
    margin-left: 0;
    margin-right: 0;
  }

  .image0 img,
  .image2 img,
  .image3 img {
    font-size: 14px;
    max-width: 600px;
    margin-left: 0;
    margin-right: 0;
  }

  .text1,
  .text2,
  .text3 {
    font-size: 14px;
    max-width: 600px;
    margin-left: 0;
    margin-right: 0;
  }

  .container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .text1 {
    padding: 0;
  }

  .marquee-container {
    height: 6rem;
  }

  .marquee {
    animation: marquee 20s linear infinite;
    height: 2.5rem;
  }

  .marquee span {
    font-size: 20px;
  }

  .section {
    font-size: 14px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .half-modal-container {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  html {
    margin-left: 1rem;
  }
  .container,
  .container2,
  .container3,
  .container4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    max-width: 600px;
    margin-left: 0;
    margin-right: 0;
  }

  .image0 img,
  .image2 img,
  .image3 img {
    font-size: 14px;
    max-width: 600px;
    margin-left: 0;
    margin-right: 0;
  }

  .text1,
  .text2,
  .text3 {
    font-size: 14px;
    max-width: 600px;
    margin-left: 0;
    margin-right: 0;
  }

  .marquee-container {
    height: 6rem;
  }

  .marquee {
    animation: marquee 20s linear infinite;
    height: 2.5rem;
  }

  .marquee span {
    font-size: 20px;
  }

  .section {
    font-size: 14px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .half-modal-container {
    width: 90%;
    margin: 0 auto;
  }
}
