/*.navBar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  padding: 10px;
}
*/

.navBar-content {
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: #333;
  padding: 10px;
}

.navButton {
  color: #f8fcfa;
  text-decoration: none;
  margin: 0 10px;
  font-size: 16px;
  transition: color 0.3s ease;
  background-color: #333;
  height: 50px;
  border-radius: 10px;
}

.navButton.active {
  color: #77ebf5;
}

.navButton:hover {
  transform: scale(1.1);
}

.navButtonContainer {
  display: flex;
  align-items: center;
}

.booking {
  background-color: #333;
  margin-left: 3rem;
  display: flex;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupContent {
  background-color: #fff;
  width: 500px;
  padding: 20px;
}

.bookingContainer {
  display: flex;
  justify-content: center;
}

.socialIcons {
  display: flex;
  align-items: center;
  margin-left: 4rem;
  gap: 1rem;
  font-size: 1.7rem;
}

.socialIcons a {
  margin-right: 10px;
  color: #f9f7f7;
}

.socialIcons a:last-child {
  margin-right: 0;
}

#logo_red {
  height: 40px;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  margin-right: 6rem;
}

/*
@media screen and (max-width: 800px) {
  .navBar {
    flex-direction: column;
  }

  .navButtonContainer {
    flex-direction: column;
    align-items: center;

    justify-content: left;
  }
}
*/

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .responsive-nav {
    flex-direction: column;
    align-items: center;
  }

  .logoContainer {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .navButtonContainer {
    margin-bottom: 2rem;
  }

  .booking {
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .socialIcons {
    margin-left: 0;
  }

  .navButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 5rem;
    margin-left: 5rem;
  }
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
  .responsive-nav {
    flex-direction: column;
    align-items: center;
  }

  .logoContainer {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .navButtonContainer {
    margin-bottom: 2rem;
  }

  .booking {
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .socialIcons {
    margin-left: 0;
  }

  .navButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 5rem;
    margin-left: 5rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .responsive-nav {
    flex-direction: column;
    align-items: center;
  }

  .logoContainer {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .navButtonContainer {
    margin-bottom: 2rem;
  }

  .booking {
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .socialIcons {
    margin-left: 1rem;
  }

  .navButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .responsive-nav {
    flex-direction: column;
    align-items: center;
  }

  .logoContainer {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .navButtonContainer {
    margin-bottom: 2rem;
  }

  .booking {
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .socialIcons {
    margin-left: 1rem;
  }

  .navButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .navBar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
